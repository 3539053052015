import { useEffect, useState } from 'react';
import { ModalProps } from './types';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, CaptchaContent, Content } from './styles';
import ModalCustom from '../ModalCustom';
import { message } from 'antd';

const REACT_APP_SITE_KEY = '6Le38bQpAAAAACwrxYFZlc6AN8Di_mKHCpgMi91f';

export const EditalDownloadModal = ({
  edital,
  isOpen,
  setEditalInfo,
  onClose,
}: ModalProps) => {
  const [token, setToken] = useState<string | null>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTokenAndEditalInfo = async () => {
      try {
        setLoading(true);
        
        const response = await fetch(
          'https://jornal.prod.back.mageserver.com.br/auth/login',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: "admin@bbmjornal.com.br",
              password: "bbm2024"
            }),
          },
        );

        if (!response.ok) {
          throw new Error('Falha na autenticação');
        }

        const data = await response.json();
        const accessToken = data.accessToken;

        const editalResponse = await fetch(
          `https://jornal.prod.back.mageserver.com.br/edital/${edital.uniqueId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (!editalResponse.ok) {
          throw new Error('Falha ao buscar edital');
        }

        const editalInfo = await editalResponse.json();
        console.log('Dados do edital:', editalInfo); 

        if (editalInfo && editalInfo.documento) {
          edital.documentosDoEdital = editalInfo.documento;
        }

        setEditalInfo && setEditalInfo(editalInfo);

      } catch (err) {
        console.error('Erro ao buscar dados:', err);
        message.error('Falha ao obter informações do edital');
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchTokenAndEditalInfo();
    }
  }, [isOpen, edital, setEditalInfo]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      
      if (edital.documentosDoEdital) {
        window.open(edital.documentosDoEdital, '_blank');
      } else {
        console.log('Link do documento não encontrado');
        message.error('Falha ao obter o link do documento');
      }
    } catch (err) {
      console.error('Erro ao abrir documento:', err);
      message.error('Erro ao tentar abrir o documento');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCustom isOpen={isOpen} setModalState={onClose} width="390px">
      <Content>
        <div>
          <h3>Caso queira visualizar o edital, clique abaixo:</h3>
          <CaptchaContent>
            <ReCAPTCHA
              sitekey={REACT_APP_SITE_KEY}
              onChange={(key) => setToken(key)}
            />
          </CaptchaContent>
          <Button 
            disabled={!token || loading} 
            onClick={handleSubmit}
          >
            {loading ? 'Carregando...' : 'Visualizar Edital'}
          </Button>
        </div>
      </Content>
    </ModalCustom>
  );
};